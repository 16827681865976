import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "form" ]

  connect() {
    function showForm() {
      var w3w = document.getElementById("w3w-form-div");
      w3w.classList.remove("hidden");
      var w3wCurrent = document.getElementById("currentw3w");
      w3wCurrent.classList.remove("hidden");
      var spin = document.getElementById("spin-div");
      spin.classList.add("hidden");
    }
    setTimeout(showForm, 1000)
  }

  update() {

    // hide the form
    var w3w = document.getElementById("w3w-form-div");
    w3w.classList.add("hidden");
    // hide current
    var w3wCurrent = document.getElementById("currentw3w");
    w3wCurrent.classList.add("hidden");
    // show the spinner
    var spin = document.getElementById("spin-div");
    spin.classList.remove("hidden");


    async function fetchCoords(words) {
      const response = await what3words.api.convertToCoordinates(words)
      return response;
    }

    event.preventDefault()
    var words = document.getElementById('w3w-input').value
    var w3w = document.getElementById('w3w')
    w3w.value = words

    var addressw3w = document.getElementById('w3w-input')
    addressw3w.value = words

    console.log(addressw3w.value)
    var w3wlat = document.getElementById('w3wlat')
    var w3wlng = document.getElementById('w3wlng')

    fetchCoords(words)
    .then(function(response) {
      w3wlat.value = response.coordinates.lat
      w3wlng.value = response.coordinates.lng
      document.getElementById('form-id').requestSubmit()
      document.addEventListener("turbo:frame-render", async (event) => {
        function showForm() {
          var w3w = document.getElementById("w3w-form-div");
          w3w.classList.remove("hidden");
          var w3wCurrent = document.getElementById("currentw3w");
          w3wCurrent.classList.remove("hidden");
          var spin = document.getElementById("spin-div");
          spin.classList.add("hidden");
          var successmsg = document.getElementById("w3w-success-div");
          if (successmsg !== null ) {
            successmsg.classList.remove("hidden");
          }
        }
        setTimeout(showForm, 1000)

        function fadeOutEffect() {
          var fadeTarget = document.getElementById("w3w-success-div");
          var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
              fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
              fadeTarget.style.opacity -= 0.1;
            } else {
              clearInterval(fadeEffect);
            }
          }, 200);
        }
        setTimeout(fadeOutEffect, 3000)
      })
    });

  }

}
