import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="close-modal"
export default class extends Controller {
  static targets = ["editButton"];

  connect() {
    console.log("image connected")
  }

  editImage() {
    event.preventDefault()
    var dz = document.getElementById('edit-dropzone')
    var editImage = document.getElementById('edit-image')
    dz.classList.remove('hidden')
    editImage.classList.add('hidden')
  }

  toggleOriginal(){
    event.preventDefault()
    var dz = document.getElementById('edit-dropzone')
    var editImage = document.getElementById('edit-image')
    dz.classList.add('hidden')
    editImage.classList.remove('hidden')
    var deleteImageField = document.getElementById('delete-image-field')
    deleteImageField.value = false
  }

  removeOriginal(){
    event.preventDefault()
    var dz = document.getElementById('edit-dropzone')
    var editImage = document.getElementById('edit-image')
    dz.classList.remove('hidden')
    editImage.classList.add('hidden')
    var deleteImageField = document.getElementById('delete-image-field')
    deleteImageField.value = true
  }
}
