import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
    'add'
  ]

  connect() {
    console.log("gsc connect")
  }


  showHide() {
    // Check if the dropdown is showing and show / hide based on state
    var cl = Array.from(this.addTarget.classList)
    if (cl.includes("hidden")){
      this.addTarget.classList.remove("hidden")
    }else{
      this.addTarget.classList.add("hidden")
    }
  }
}
