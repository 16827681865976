import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="close-modal"
export default class extends Controller {
    static targets = [
    'down',
    'up'
  ]

  connect() {
    console.log("chev connect")
  }

  showUp() {
    // Hide all other up chevs
    // var upChevs = document.getElementsByClassName("up-chevron")
    // Array.prototype.forEach.call(upChevs, element => {
    //   element.classList.add('hidden')
    // });
    // // Show all other down chevs
    // var downChevs = document.getElementsByClassName("down-chevron")
    // Array.prototype.forEach.call(downChevs, element => {
    //   element.classList.remove('hidden')
    // });
    // Show the targeted up chev
    this.downTarget.classList.add('hidden')
    this.upTarget.classList.remove('hidden')
  }

  showDown() {
    // Hide all other down chevs
    // var downChevs = document.getElementsByClassName("down-chevron")
    // Array.prototype.forEach.call(downChevs, element => {
    //   element.classList.add('hidden')
    // });
    // // Show all other up chevs
    // var upChevs = document.getElementsByClassName("down-chevron")
    // Array.prototype.forEach.call(upChevs, element => {
    //   element.classList.remove('hidden')
    // });
    // Show the targeted down chev
    this.downTarget.classList.remove('hidden')
    this.upTarget.classList.add('hidden')
  }
}
