import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {

      this.sortable = Sortable.create(this.element, {
      group: {
          name: 'content',
           put: false},
      animation: 150,
      handle: '.content-handle',
      forceFallback: true,
      filter: 'no-drag',
      onMove: function (e) { return e.related.className.indexOf('no-drag') === -1;  },
      onEnd: this.end.bind(this)
    })

    // var nestedSortables = document.getElementsByClassName("nested-sortable-content")
    // for (var i = 0; i < nestedSortables.length; i++) {
    //   new Sortable(nestedSortables[i], {
    //     group: 'content' + i,
    //     animation: 150,
    //     pull: false,
    //     put: false,
    //     handle: '.content-handle',
    //     onEnd: this.end.bind(this),
    //     filter: 'no-drag',
    //     onMove: function (e) { return e.related.className.indexOf('no-drag') === -1;  },
    //     swapThreshold: 0.65
    //   });
    // }
  }

  end(event) {
    // console.log(this.data.get('url'))
    let id = event.item.dataset.id
    console.log(event.newIndex)
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    let url = this.data.get("url")
    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}
