import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.close()
    }, 6000);
  }

   close () {
    this.element.classList.add('transform', 'opacity-0', 'transition', 'duration-1000');
    setTimeout(() => this.element.remove(), 600)
  }
}
