import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "address", "place", "addressField", "placeField"]

  connect() {
    console.log("toggle connect")
  }

  switch({params}){
    // Identify which button has been clicked and adjust class lists
    this.placeTarget.classList = ""
    this.addressTarget.classList = ""
    if (params["type"] == "place"){
      this.placeTarget.classList = "relative w-1/2 bg-white border-gray-200 rounded-full py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8"
      this.addressTarget.classList = "ml-0.5 relative w-1/2 border border-transparent rounded-full py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8"
      this.addressFieldTarget.classList.add('hidden')
      this.placeFieldTarget.classList.remove('hidden')
    }else{
      this.placeTarget.classList = "ml-0.5 relative w-1/2 border border-transparent rounded-full py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8"
      this.addressTarget.classList = "relative w-1/2 bg-white border-gray-200 rounded-full py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8"
      this.addressFieldTarget.classList.remove('hidden')
      this.placeFieldTarget.classList.add('hidden')
    }

  }

}
