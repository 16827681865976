import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {

  }

  gcSelected({ params }) {

    var guideId = params["id"];

    // Set the background and text of the button
    var button = document.getElementById('gc_button_' + guideId );
    button.classList.add("bg-indigo-600", "text-white");

    // Set the background of all guides
    var allGuidesBtn = document.getElementById('gc_button_all_guides');
    allGuidesBtn.classList.remove("bg-indigo-600", "text-white");

    // Set the all guides to false
    var allGuidesInput = document.getElementById('gc_all_guides');
    allGuidesInput.checked = false

    // Set the no guides to false
    // var allGuidesInput = document.getElementById('gc_no_guides');
    // allGuidesInput.checked = false

    // Set the background of No Guides
    var noGuides = document.getElementById('gc_button_no_guides');
    noGuides.classList.remove("bg-indigo-600", "text-white");

    // Update the guide_id in the form so gc gets created
    var input = document.getElementById('gc_input_guide_' + guideId );

    input.value = guideId

    // Update the data-action for the button
    button.setAttribute('data-action', 'gc#gcDeselected')

  }

  gcDeselected({ params }) {

    var guideId = params["id"];

    // Set the background and text of the button
    var button = document.getElementById('gc_button_' + guideId );
    button.classList.remove("bg-indigo-600", "text-white");

    // Update the guide_id in the form so gc gets created
    var input = document.getElementById('gc_input_guide_' + guideId );
    input.value = ""

    // Check if any guides are selected if not highlight No Guides
    var inputs = document.getElementsByClassName('gid_input')
    var x = []
    for (var i = 0; i < inputs.length; i++) {
      x.push(inputs.item(i).value)
    }
    x = x.filter(item => item);
    if (x.length == 0) {
      var noGuides = document.getElementById('gc_button_no_guides');
      noGuides.classList.add("bg-indigo-600", "text-white");
    }

    // Update the data-action for the button
    button.setAttribute('data-action', 'gc#gcSelected')

  }

  gcAllSelected() {

    // Set the background of all guides
    var allGuidesBtn = document.getElementById('gc_button_all_guides');
    allGuidesBtn.classList.add("bg-indigo-600", "text-white");

    // Set the all guides to true
    var allGuidesInput = document.getElementById('gc_all_guides');
    allGuidesInput.checked = true

    if (document.getElementById('gc_button_no_tags') != null ){
      // Set the background of no tags
      var noTags = document.getElementById('gc_button_no_tags');
      noTags.classList.add("bg-indigo-600", "text-white");

      // Set the background and data-action of the other tags
      var tagButtons = document.querySelectorAll('.tag-button')
      tagButtons.forEach((tagButton) => {
        tagButton.classList.remove("bg-indigo-600", "text-white");
        tagButton.setAttribute('data-action', 'gc#tagSelected')
      });

      // Remove all other tags from forms
      var inputs = document.getElementsByClassName('tid_input')
      for (var i = 0; i < inputs.length; i++) {
        inputs.item(i).value = ""
      }
    }
    // Set the background and data-action of the other guides
    var guideButtons = document.querySelectorAll('.guide-button')
    guideButtons.forEach((guideButton) => {
      guideButton.classList.remove("bg-indigo-600", "text-white");
      guideButton.setAttribute('data-action', 'gc#gcSelected')
    });

    // Set the background of no guides
    var noGuides = document.getElementById('gc_button_no_guides');
    noGuides.classList.remove("bg-indigo-600", "text-white");

    // Remove all other guides from form
    var inputs = document.getElementsByClassName('gid_input')
    for (var i = 0; i < inputs.length; i++) {
      inputs.item(i).value = ""
    }
  }

  gcNoneSelected() {
    // Set the background of "all guides"
    var allGuidesBtn = document.getElementById('gc_button_all_guides');
    allGuidesBtn.classList.remove("bg-indigo-600", "text-white");

    // Set the all guides to false
    var allGuidesInput = document.getElementById('gc_all_guides');
    allGuidesInput.checked = false

    // Set the no guides to true
    // var allGuidesInput = document.getElementById('gc_no_guides');
    // allGuidesInput.checked = true

    // Set the background of no guides
    var noGuides = document.getElementById('gc_button_no_guides');
    noGuides.classList.add("bg-indigo-600", "text-white");

    // Set the background and data-action of the other guides
    var guideButtons = document.querySelectorAll('.guide-button')
    guideButtons.forEach((guideButton) => {
      guideButton.classList.remove("bg-indigo-600", "text-white");
      guideButton.setAttribute('data-action', 'gc#gcSelected')
    });

    // Remove all other guides from forms
    var inputs = document.getElementsByClassName('gid_input')
    for (var i = 0; i < inputs.length; i++) {
      inputs.item(i).value = ""
    }
  }


  // Tag selection


  tagSelected({ params }) {

    // check if all guides selected if so do nothing

    if (document.getElementById('gc_all_guides').checked === false) {


      var tagId = params["id"];

      // Set the background and text of the button
      var button = document.getElementById('gct_button_' + tagId );
      button.classList.add("bg-indigo-600", "text-white");
      console.log(button.classList)

      // Set the background of No Tags
      var noTags = document.getElementById('gc_button_no_tags');
      noTags.classList.remove("bg-indigo-600", "text-white");

      // Update the guide_id in the form so gc gets created
      var input = document.getElementById('gc_input_tag_' + tagId );

      input.value = tagId

      // Update the data-action for the button
      button.setAttribute('data-action', 'gc#tagDeselected')
      }
    console.log(button.classList)
  }


  tagDeselected({ params }) {

    var tagId = params["id"];

    // Set the background and text of the button
    var button = document.getElementById('gct_button_' + tagId );
    button.classList.remove("bg-indigo-600", "text-white");

    // Update the tag_id in the form so tag gets created
    var input = document.getElementById('gc_input_tag_' + tagId );
    input.value = ""

    // Check if any tags are selected if not highlight No Tags
    var inputs = document.getElementsByClassName('tid_input')
    var x = []
    for (var i = 0; i < inputs.length; i++) {
      x.push(inputs.item(i).value)
    }
    x = x.filter(item => item);
    if (x.length == 0) {
      var noTags = document.getElementById('gc_button_no_tags');
      noTags.classList.add("bg-indigo-600", "text-white");
    }

    // Update the data-action for the button
    button.setAttribute('data-action', 'gc#tagSelected')

  }

  noTagSelected() {

    // Set the background of no tags
    var noTags = document.getElementById('gc_button_no_tags');
    noTags.classList.add("bg-indigo-600", "text-white");

    // Set the background and data-action of the other tags
    var tagButtons = document.querySelectorAll('.tag-button')
    tagButtons.forEach((tagButton) => {
      tagButton.classList.remove("bg-indigo-600", "text-white");
      tagButton.setAttribute('data-action', 'gc#tagSelected')
    });

    // Remove all other tags from forms
    var inputs = document.getElementsByClassName('tid_input')
    for (var i = 0; i < inputs.length; i++) {
      inputs.item(i).value = ""
    }
  }
}










