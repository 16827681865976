import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: {
          name: 'subtheme',
           put: false},
      animation: 150,
      handle: '.st-handle',
      forceFallback: true,
      filter: 'no-drag',
      onMove: function (e) { return e.related.className.indexOf('no-drag') === -1;  },
      onEnd: this.end.bind(this)
    })


    // var nestedSortables = document.getElementsByClassName("nested-sortable")
    // for (var i = 0; i < nestedSortables.length; i++) {
    //   new Sortable(nestedSortables[i], {
    //     group: 'nested' + i,
    //     animation: 150,
    //     handle: '.st-handle',
    //     pull: false,
    //     put: false,
    //     onEnd: this.end.bind(this),
    //     filter: 'no-drag',
    //     onMove: function (e) { return e.related.className.indexOf('no-drag') === -1;  },
    //     swapThreshold: 0.65
    //   });
    // }

    // addEventListener("turbo:frame-render", ((event) => {

    //   var nestedSortables = document.getElementsByClassName("nested-sortable")
    //   console.log(nestedSortables)
    //   nestedSortables.forEach(element => {
    //     element.sortable("destroy")
    //     new Sortable(nestedSortables[i], {
    //       group: 'nested',
    //       animation: 150,
    //       handle: '.st-handle',
    //       onEnd: this.end.bind(this),
    //       swapThreshold: 0.65
    //     });
    //   });
    // }))
  }

  end(event) {
    console.log(event)
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    let url = this.data.get("url")
    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}
