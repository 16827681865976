import ColorPicker from 'stimulus-color-picker'

export default class extends ColorPicker {
    connect() {
        super.connect()
        console.log('Color picker controller connected!')
    }

    // Callback when the color is saved
    onSave(color) {
      // super.onSave(color)

      // var str = window.location.href;
      // str = str.substring(0, str.length - 4);
      // console.log(str);

      // const url = str + "guide_color_update";
      // const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

      // try {
      //     const response = fetch(url, {
      //         method: "POST",
      //         headers: {
      //             "Content-Type": "application/json",
      //             "X-CSRF-Token": csrfToken
      //         },
      //         body: JSON.stringify({ guide_color: this.inputTarget.value  })
      //     });
      // } catch (error) {
      //     console.error("Request error:", error);
      // }
      super.onSave(color)

      const url = this.inputTarget.getAttribute('path');
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

      try {
        if (url) {
          const response = fetch(url, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": csrfToken
              },
              body: JSON.stringify({guide_color: this.inputTarget.value})
          });
        }
      } catch (error) {
          console.error("Request error:", error);
      }
    }

    // You can override the components options with this getter.
    // Here are the default options.
    get componentOptions() {
        return {
            preview: true,
            hue: true,

            interaction: {
                input: true,
                hex: true,
                save: true,
            },
        }
    }

    // You can override the swatches with this getter.
    // Here are the default options.
    get swatches() {
        return [
            '#4f46e5',
            '#10b981',
            '#78716c',
            '#2563eb',
            '#dc2626',
            '#f59e0b'
            // this.inputTarget.value
        ]
    }
}
