import {Controller} from '@hotwired/stimulus'
import Dropzone from "dropzone";
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
} from "../helpers/dropzone";

export default class extends Controller {
  static targets = [
    'input',
    'line1',
    'line2',
    'city',
    'state',
    'postal_code',
    'postal_town',
    'country',
    'lat',
    'lng',
    'name'
  ]


  connect() {
    console.log("Content place controller is connected");
    if (window.google) {
      this.initGoogle();
    }
  }

  initGoogle() {
    // setup autocomplete
    // console.log(`Google maps is initialized and the content place controller knows about it`)
    // console.log(google);
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
      fields: ["address_components", "geometry", "photos", "opening_hours", "rating", "name", "place_id"],
      types: ["establishment"],
      componentRestrictions: {country: ["gb"]},
    })
    this.autocomplete.addListener('place_changed', this.placeSelected.bind(this))

  }

  placeSelected() {

    // Add loading spinner
    if (this.nameTarget.value === "") {
      var spinDiv = document.getElementById('spin-div')
      spinDiv.classList.remove('hidden')
    }

    setTimeout(() => {
      // use values from autocomplete
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      // Unhide the place form
      var hiddenDiv = document.getElementById('hidden-div')
      hiddenDiv.classList.remove("hidden")

      // Add the name to the name field
      this.nameTarget.value = place.name;

      // Deal with the photos
      var googleImageDiv = document.getElementById('google-image-div');
      var googleImage = document.getElementById('google-image-and-url');
      var dzImageDiv = document.getElementById('place-image-dz');
      var toggleImage = document.getElementById('content_place_attributes_use_google_image');
      var toggleImageDiv = document.getElementById('google-image-toggle');

      if (place.photos === undefined) {
        googleImageDiv.classList.add('hidden');
        dzImageDiv.classList.remove('hidden');
      } else {
        toggleImage.checked = "checked";
        toggleImageDiv.classList.remove('hidden');
        googleImageDiv.classList.remove('hidden');
        googleImage.classList.remove('hidden')
        dzImageDiv.classList.add('hidden');

        // add the google image if it is there
        // var placeImage = document.getElementById("api-place-image")
        // placeImage.src = place.photos[0].getUrl({ 'maxWidth': 700, 'maxHeight': 400 });

        setTimeout(() => {
          var placeImage = document.getElementById("api-place-image")
          placeImage.src = place.photos[0].getUrl({'maxWidth': 700, 'maxHeight': 400});
          placeImage.classList.remove('hidden')
          var spinDiv2 = document.getElementById('spin-div-2')
          spinDiv2.classList.add('hidden')
        }, 800);

        // var placeImage = document.getElementById("api-place-image")
        // placeImage.src = place.photos[0].getUrl({ 'maxWidth': 700, 'maxHeight': 400 });

        // setTimeout(() => {
        //   var placeImageSpin = document.getElementById("api-place-image-spinner")
        //   placeImageSpin.classList.add("hidden")

        //   placeImage.classList.remove("hidden")
        // }, 600)

        // remove the link div
        var removeLinkDiv = document.getElementsByClassName('dz-remove')[0]
        if (removeLinkDiv != undefined) {
          removeLinkDiv.click()
        }

        // Add the Google image url to the form for processing later
        var googleImageUrl = document.getElementById('google-image-url')
        googleImageUrl.value = place.photos[0].getUrl()

      }

      this.latTarget.value = place.geometry.location.lat();
      this.lngTarget.value = place.geometry.location.lng();

      // console.log(place.place_id)
      
      // console.log(place)
      // console.log(place.rating)
      // console.log(place.name)
      // console.log(place.opening_hours.weekday_text)


      // Set all address fields to nil before adding fresh data
      this.line1Target.value = ""
      this.postal_townTarget.value = ""
      this.postal_codeTarget.value = ""
      this.stateTarget.value = ""
      this.countryTarget.value = ""

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      // REMEMBER: create new autocomplete form for other countries
      for (const component of place.address_components) {
        const componentType = component.types[0];
        
        // console.log(component.types)
        switch (componentType) {

          case "street_number": {
            this.line1Target.value = ""
            this.line1Target.value = `${component.long_name} ${this.line1Target.value}`;
            break;
          }

          case "route": {
            this.line1Target.value += component.short_name;
            break;
          }

            // case "locality":
            //   this.cityTarget.value = component.long_name;

            //   break;

          case "postal_town": {
            this.postal_townTarget.value = component.long_name;
            break;
          }

          case "postal_code": {
            this.postal_codeTarget.value = `${component.long_name}${this.postal_codeTarget.value}`;
            break;
          }

          case "postal_code_suffix": {
            this.postal_codeTarget.value = `${this.postal_codeTarget.value}-${component.long_name}`;
            break;
          }

            // case "administrative_area_level_1": {
            //   this.stateTarget.value = component.short_name;
            //   break;
            // }

          case "administrative_area_level_2": {
            this.stateTarget.value = component.short_name;
            break;
          }

          case "country":
            this.countryTarget.value = component.short_name;
            break;
        }
      }

      // If the Address Line 1 is empty add the name as the Address Line 1
      if (this.line1Target.value === '') {
        this.line1Target.value = place.name
      }

      // Put the phone, url, rating and opening hours in the rich text field, user can overwrite / edit if they want + the hidden fields

      var urlField = document.getElementById('url-field');
      var ratingField = document.getElementById('rating-field');
      var phoneField = document.getElementById('phone-field');
      var textField = document.getElementById('place-rich-text-field');
      textField.value = ""

      if (place.rating != undefined) {
        textField.value = "<br> <br> <strong>Google Places Rating:</strong> <br> ⭐️ " + place.rating + " / 5"
        ratingField.value = place.rating
      }

      if (place.opening_hours != undefined) {
        var openingHours = String(place.opening_hours.weekday_text).split(",").join("<br />")
        textField.value = textField.value + "<br><strong>Opening Hours:</strong> <br>" + openingHours
      }

      var request = {
        placeId: place.place_id,
        fields: ['formatted_phone_number', 'website', 'type']
      };

      this.service = new google.maps.places.PlacesService(this.inputTarget);
      this.service.getDetails(request, callback);

      function callback(place, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK && place.formatted_phone_number != undefined) {
          var site = place.website.split('?')[0];
          const place_type_key = place.types[0];
          console.log('this is the place type: ' + place_type_key)

          var id = null
          for (const key in placeTypesData) {
            // Check if the API value is present in the array associated with the current ID
            if (placeTypesData[key].includes(place_type_key)) {
              id = key; // Return the matching ID
              break;
            }
          }
          console.log('THis is the type-id : ', id)

          if (id != null){
            const hiddenField = document.getElementById('place_type_field');
            const selected = document.querySelector('[type_id="' + id + '"]');

            hiddenField.value = id;
            const selectedType = document.getElementById('place_type_selected');

            const select_option = selected.innerHTML;
            selectedType.innerHTML = select_option;
            document.querySelector('#place_type_selected > span.ticked').remove();
            var type_options = document.getElementsByClassName('types-targets')
            Array.from(type_options).forEach(li => li.classList.remove("active"));
            selected.classList.add("active");
          }

          // if (place_type_key != undefined) {
          //   const selectElement = document.getElementById("content_place_type_id");
          //   for (let i = 0; i < selectElement.options.length; i++) {
          //     const option = selectElement.options[i];

          //     if (option.getAttribute("type_key") === place_type_key) {
          //       option.selected = true;
          //       break;
          //     }
          //   }
          // }


          textField.editor.activateAttribute("href", site);
          textField.value = textField.value + "<br><strong>Phone Number:</strong> <br>" + place.formatted_phone_number;
          phoneField.value = place.formatted_phone_number;
          urlField.value = site;
        }
      }

      spinDiv.classList.add('hidden')
    }, 600);
  }

  toggleGoogleImage() {
    var toggleImage = document.getElementById('content_place_attributes_use_google_image');
    var googleImageDiv = document.getElementById('google-image-and-url');
    var dzImageDiv = document.getElementById('place-image-dz');

    if (toggleImage.checked === true) {
      googleImageDiv.classList.remove('hidden');
      dzImageDiv.classList.add('hidden');

      var googleImageUrl = document.getElementById('google-image-url');

      if (this.nameTarget.value != '') {
        this.placeSelected();
      }
      // console.log(googleImageUrl.value);

    } else {

      googleImageDiv.classList.add('hidden');
      dzImageDiv.classList.remove('hidden');

      // Remove the Google image url from the form
      var googleImageUrl = document.getElementById('google-image-url')
      googleImageUrl.value = ''
      // console.log(googleImageUrl.value)
    }
  }
}






