// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)




import Flatpickr from 'stimulus-flatpickr'

import Clipboard from 'stimulus-clipboard'

// import PlacesAutocomplete from 'stimulus-places-autocomplete'


application.register('clipboard', Clipboard)

// application.register('places', PlacesAutocomplete)
