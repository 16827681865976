import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input',
    'line1',
    'line2',
    'city',
    'state',
    'postal_code',
    'postal_town',
    'country',
    'lat',
    'lng',
  ]

  connect() {
    console.log("Place address american controller is connected");
    if(window.google) {
      this.initGoogle();
    }
  }

  initGoogle() {
    // setup autocomplete
    console.log(`Google maps is initialized and the address controller knows about it`);
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
      fields: ["address_components", "geometry", "name"],
      types: ["establishment"],
      componentRestrictions: { country: ["us"] },
    })
    this.autocomplete.addListener('place_changed', this.placeSelected.bind(this))
  }

  placeSelected() {
    // use values from autocomplete
    const place = this.autocomplete.getPlace();

    if(!place.geometry) {
      return;
    }

    this.latTarget.value = place.geometry.location.lat();
    this.lngTarget.value = place.geometry.location.lng();

    // console.log(place.address_components)
    // console.log(place.photos)
    // console.log(place.opening_hours)
    // console.log(place.rating)
    // console.log(place.name)


    // Set all address fields to nil before adding fresh data
    this.line1Target.value = ""
    this.line2Target.value = ""
    this.cityTarget.value = ""
    this.postal_codeTarget.value = ""
    this.stateTarget.value = ""
    this.countryTarget.value = ""

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          this.line1Target.value = ""
          this.line1Target.value = `${component.long_name} ${this.line1Target.value}`;
          break;
        }

        case "route": {
          this.line1Target.value += component.short_name;
          break;
        }

        case "locality": {
          this.cityTarget.value = component.long_name;
          break;
        }

        // case "postal_town": {
        //   this.postal_townTarget.value = component.long_name;
        //   break;
        // }

        case "postal_code": {
          this.postal_codeTarget.value = `${component.long_name}${this.postal_codeTarget.value}`;
          break;
        }

        case "postal_code_suffix": {
          this.postal_codeTarget.value = `${this.postal_codeTarget.value}-${component.long_name}`;
          break;
        }

        case "neighborhood": {
          this.line2Target.value = ""
          this.line2Target.value = `${component.long_name}`;
          break;
        }

        // case "administrative_area_level_1": {
        //   this.stateTarget.value = component.short_name;
        //   break;
        // }

        case "administrative_area_level_2": {
          this.stateTarget.value = component.short_name;
          break;
        }

        case "country":
          this.countryTarget.value = component.short_name;
          break;
      }
    }

    // If the Address Line 1 is empty add the name as the Address Line 1
    if (this.line1Target.value === '') {
      this.line1Target.value = place.name
    }
  }
}
