import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
  ]

  connect() {
    console.log("validation connect")
  }


  checkContent() {
    if (document.getElementById("gsc-text").value === ''){
      event.preventDefault()
      document.getElementById("warn").classList.remove("hidden")
    }
  }

  // deleteContent() {
  //   event.preventDefault()
  //   var field = document.getElementById("delete-field")
  //   field.value = true
  //   var form = document.getElementById("gsc-form");
  //   form.submit();
  // }
}
