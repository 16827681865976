import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'placeType'
  ]

  filterTypes(e) {
    const selected = e.currentTarget;

    const typeId = selected.getAttribute("type_id");
    const selectedType = document.getElementById('place_type_selected');
    const guide_id = selectedType.getAttribute("data-guide-id");
    const select_option = selected.innerHTML;
    selectedType.innerHTML = select_option;
    document.querySelector('#place_type_selected > span.ticked').remove();
    this.placeTypeTargets.forEach(li => li.classList.remove("active"));
    selected.classList.add("active");

    const placesController = this.application.getControllerForElementAndIdentifier(document.querySelector('#guide-landing'), 'places')
    if (placesController) {
      placesController.clearMarkers();
      placesController.addMarkers(window.sharedMapInstance, typeId);
    }
  }
}
