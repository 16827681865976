import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = [ "dollars", "euros", "pounds" ]

  connect() {
  }

  changePrice(event) {
    var md = document.getElementById('monthly-div')
    var ad = document.getElementById('annual-div')

    if (event.params.interval === 'annual'){
      md.classList.add('hidden')
      ad.classList.remove('hidden')
    }else{
      md.classList.remove('hidden')
      ad.classList.add('hidden')
    }
  }

  changeCurrency(event) {
    var dollars = document.getElementsByClassName('dollars')
    var euros = document.getElementsByClassName('euros')
    var pounds = document.getElementsByClassName('pounds')

    var dollarRadios = document.getElementsByClassName('dollar-radio')
    var poundRadios = document.getElementsByClassName('pound-radio')
    var euroRadios = document.getElementsByClassName('euro-radio')

    if (event.params.currency === 'dollar') {

      for (const x of dollars) { x.classList.remove('hidden') }
      for (const x of euros) { x.classList.add('hidden') }
      for (const x of pounds) { x.classList.add('hidden') }

      for (const x of dollarRadios) { x.classList.add('bg-indigo-500', 'text-white') }
      for (const x of euroRadios) { x.classList.remove('bg-indigo-500', 'text-white') }
      for (const x of poundRadios) { x.classList.remove('bg-indigo-500', 'text-white') }

    } else if (event.params.currency === 'euro') {

      for (const x of dollars) { x.classList.add('hidden') }
      for (const x of euros) { x.classList.remove('hidden') }
      for (const x of pounds) { x.classList.add('hidden') }

      for (const x of dollarRadios) { x.classList.remove('bg-indigo-500', 'text-white') }
      for (const x of euroRadios) { x.classList.add('bg-indigo-500', 'text-white') }
      for (const x of poundRadios) { x.classList.remove('bg-indigo-500', 'text-white') }

    } else {

      for (const x of dollars) { x.classList.add('hidden') }
      for (const x of euros) { x.classList.add('hidden') }
      for (const x of pounds) { x.classList.remove('hidden') }

      for (const x of dollarRadios) { x.classList.remove('bg-indigo-500', 'text-white') }
      for (const x of euroRadios) { x.classList.remove('bg-indigo-500', 'text-white') }
      for (const x of poundRadios) { x.classList.add('bg-indigo-500', 'text-white') }
    }
  }
}
