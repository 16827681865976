import { Controller } from "@hotwired/stimulus"

// Some JS to remove the "add content" card when deleting a Subtheme
export default class extends Controller {
  connect() {
    console.log("delete st")
  }

  hideAddContent({params}) {
    var subthemeId = params["id"];
    var addContentCard = document.getElementById('add-content-link-' + subthemeId)
    addContentCard.classList.add('hidden')
  }
}
