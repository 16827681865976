import { Controller } from "@hotwired/stimulus"
import IconPicker from 'vanilla-icon-picker'

export default class extends Controller {
  static targets = ["icon_button"]

  connect() {
    console.log("Icon controller is connected");
  }

  dropdown(){
    var icon_button = document.getElementById('icon-btn')
    var path = document.getElementById('foo');
    var field = document.getElementById('path-field');
    var data = path.getAttribute('d');
    var iconPicker = new IconPicker(icon_button, {
      theme: 'default',
      iconSource: [
         {
          key: 'fa6-regular',
          prefix: 'ai ai-',
          url: 'https://raw.githubusercontent.com/iconify/icon-sets/master/json/fa6-regular.json'
        },
         {
          key: 'fa6-solid',
          prefix: 'ai ai-',
          url: 'https://raw.githubusercontent.com/iconify/icon-sets/master/json/fa6-solid.json'
        },
      ],
      closeOnSelect: true,
    });

    iconPicker.show();

    iconPicker.on('select', instance => {
      var icon = instance["svg"]
      var p = icon.split("d=")[1].split("></path>")[0]
      var newp = p.replace(/['"]+/g, '')
      var str = newp.replace(/\\/g, '')
      path.setAttribute('d', str )
      field.value = str
    });
    iconPicker.on('hide', instance => {
      iconPicker.destroy();
    });
  }
}


