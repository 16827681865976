import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="close-modal"
export default class extends Controller {
    static targets = [
    'plus',
    'minus',
    'expandable'
  ]

  connect() {
    // console.log("faq connect")
  }

  changeIcon() {
    // Check if the text is showing if not add
    var elements = Array.from(this.expandableTarget.classList)
    if (elements.includes("hidden")){
      // Hide all other up chevs
      var upChevs = document.getElementsByClassName("minus-icon")
      Array.prototype.forEach.call(upChevs, element => {
        element.classList.add('hidden')
      });
      // Show all other down chevs
      var downChevs = document.getElementsByClassName("plus-icon")
      Array.prototype.forEach.call(downChevs, element => {
        element.classList.remove('hidden')
      });

      this.plusTarget.classList.add("hidden")
      this.minusTarget.classList.remove("hidden")
    }else{
      // Hide all other down chevs
      var downChevs = document.getElementsByClassName("plus-icon")
      Array.prototype.forEach.call(downChevs, element => {
        element.classList.add('hidden')
      });
      // Show all other up chevs
      var upChevs = document.getElementsByClassName("plus-icon")
      Array.prototype.forEach.call(upChevs, element => {
        element.classList.remove('hidden')
      });
      this.plusTarget.classList.remove("hidden")
      this.minusTarget.classList.add("hidden")
    }
  }
}

