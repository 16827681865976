import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hex-to-rgba"
export default class extends Controller {
  static targets = ['message']

  connect() {
    this.messageTarget.style.backgroundColor = this.hexToRgba(this.messageTarget.dataset.color, 0.1)
  }

  hexToRgba(hex, alpha) {
    // Remove the hash (#) character if it exists
    hex = hex.replace('#', '');

    // Parse the hexadecimal color code into RGB components
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    // Convert alpha value to a valid range (0 to 1)
    alpha = alpha >= 0 && alpha <= 1 ? alpha : 1;

    // Return the RGBA color in "rgba(r, g, b, alpha)" format
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
