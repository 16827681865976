import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="assistants"
export default class extends Controller {
  static targets = ["guideInput", "nameInput", "pdfContainer"]

  connect() {}

  changeGuide(event) {
    this.toggleFileInputs(event.target.value !== '')
  }

  toggleFileInputs(value) {
    if (value) {
      this.nameInputTarget.value = `${this.selectedGuideName()} Assistant`
    }
  }

  selectedGuideName() {
    return this.guideInputTarget.options[this.guideInputTarget.selectedIndex].text
  }
}
