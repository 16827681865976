import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
    'placeType'
  ]

  connect() {
    console.log("Select place type connected ");
  }

  selectPlaceType(e) {
    const hiddenField = document.getElementById('place_type_field');
    const selected = e.currentTarget;

    const typeId = selected.getAttribute("type_id");
    hiddenField.value = typeId;
    const selectedType = document.getElementById('place_type_selected');

    const select_option = selected.innerHTML;
    selectedType.innerHTML = select_option;
    document.querySelector('#place_type_selected > span.ticked').remove();
    this.placeTypeTargets.forEach(li => li.classList.remove("active"));
    selected.classList.add("active");
  }
}
