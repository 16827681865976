import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  async setGuide(event){
    const guide = event.target.value;
    
    const target_id  = event.target.getAttribute('data-target-id')
    const type  = event.target.getAttribute('data-type')
    const sub_theme_id = event.target.getAttribute('data-subthemeid')
    const inputElement = document.getElementById("deep-link-" + target_id);

    const content_id  = event.target.getAttribute('data-content-id')
    const sub_url = this.getUrl(type, content_id)
    var link = null;
  if (type == "content"){
    link = guide + sub_url + sub_theme_id
  }
  else{
    link = guide + sub_url + content_id
  }

    if (guide && content_id && sub_url && link) {
      try {
        inputElement.value = await this.shortenUrl(link)
      } catch (error) {
        console.error("Error generating shortened URL:", error);
      }
    }
    else{
      inputElement.value = '';
    }
  }

  getUrl(type, content_id){
    switch (type) {
      case "theme":
        return "/subthemes?theme_id=";
      case "sub_theme":
        return "/contents?sub_theme_id=";
      case "content":
        return `/contents?content_id=${content_id}&sub_theme_id=`;
      default:
        return null;
    }
  }

  async shortenUrl(url) {
    try {
      const response = await fetch("/shortened_urls/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        },
        body: JSON.stringify({ url: url })
      });
      const data = await response.json();
      return data.shortened_url;
      // Use the shortened URL as needed
    } catch (error) {
      console.error("Error generating shortened URL:", error);
      return "Unable to generate link!";
    }
  }
}
