import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "map", "listings", "color", "marker" ]

  connect() {
    console.log("places controller")
    if (typeof(google) != "undefined") {
      this.initGoogle()
    }
  }

  initGoogle() {
    const myLatLng = { lat: parseFloat(this.listingsTarget.children[0].dataset.lat), lng: parseFloat(this.listingsTarget.children[0].dataset.lng) };
    const map = new google.maps.Map(this.mapTarget, {
      zoom: 12,
      center: myLatLng,
      disableDefaultUI: true,
      zoomControl: true,
      mapId: '99f8dc22818af774'
    });

    // add markers for places
    this.addMarkers(map, '');
    window.sharedMapInstance = map;

    // add marker for the listing
    var listing = this.listingsTarget.children[0]
    var placeName = "<strong>" + listing.dataset.placeName + "</strong> <br>"
    var content = placeName

    var marker = new google.maps.Marker({
      position: myLatLng,
      map,
      title: listing.dataset.title,
      animation: google.maps.Animation.DROP,
      icon: this.pinSymbol(listing.dataset.color),
      label: {
        text: String.fromCharCode(parseInt("e88a", 16)),
        fontFamily: "Material Icons",
        color: "#ffffff",
        fontSize: "18px",
      },
    });

    var infowindow = new google.maps.InfoWindow()
    google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
        infowindow.setContent(content);
        infowindow.open(map,marker);
      };
    })(marker,content,infowindow));
  }

  addMarkers(map, type_id) {
    this.markers = [];
    Array.from(this.listingsTarget.children).forEach((listing) => {
      if(listing.dataset.lat && listing.dataset.placeRating && (type_id == ''  || type_id == listing.dataset.placeTypeId)) {
        console.log(listing.dataset.icon_code)

        var marker = new google.maps.Marker({
          position: {
            lat: parseFloat(listing.dataset.lat),
            lng: parseFloat(listing.dataset.lng),
          },
          map,
          title: listing.dataset.title,
          animation: google.maps.Animation.DROP,
          icon: this.pinSymbol(listing.dataset.color),
          label: {
            text: String.fromCharCode(parseInt(listing.dataset.icon_code, 16)),
            fontFamily: "Material Icons",
            color: "#ffffff",
            fontSize: "18px",
          },
        });
        this.markers.push(marker);

        if (listing.dataset.placeName != undefined ){
          var placeName = "<strong>" + listing.dataset.placeName + "</strong> <br>"
          var content = placeName
        };

        if (listing.dataset.placeRating != undefined ){
          var placeRating = "<br> <strong>Google Places Rating:</strong> <br> ⭐️ " + listing.dataset.placeRating + " / 5 <br>"
          var content = content + placeRating
        };
        if (listing.dataset.placePhone != undefined ){
          var placePhone = "<br><strong>Phone Number:</strong> <br>" + listing.dataset.placePhone + "<br>"
          var content = content + placePhone
        };
        if (listing.dataset.url != undefined ){
          var placeUrl = "<br> <a href=" + listing.dataset.url + ">" + "Visit Website" + "</a> <br>"
          var content = content + placeUrl
        };

        if (listing.dataset.directions != undefined) {
          var googleDirUrl = "https://www.google.com/maps/dir/?api=1&destination=" + listing.dataset.directions
          var tb = "target=" + "_blank"
          var directionsUrl = "<br> <a href=\"" + googleDirUrl + "\" target=_blank>" + "Get Directions" + "</a>"

          console.log(directionsUrl)
          var content = content + directionsUrl
        }

        var infowindow = new google.maps.InfoWindow()
        google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
          return function() {
            infowindow.setContent(content);
            infowindow.open(map,marker);
          };
        })(marker,content,infowindow));

        // console.log(this.markerTarget.dataset.markerName)
        if(this.hasMarkerTarget && this.markerTarget.dataset.markerName === listing.dataset.placeName){
          infowindow.setContent(content);
          infowindow.open(map,marker)
        }
      }
    })
  }

  pinSymbol(color) {
    return {
      path: 'M 0,0 C -2,-5 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-5 0,0 z',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#ffffff',
      strokeWeight: 1,
      scale: 1.2,
      labelOrigin: new google.maps.Point(0,-29)
    };
  }

  clearMarkers() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
    this.markers = [];
  }
}
