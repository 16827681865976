import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["name"]
    static values = { url: String }

    searchListing(event) {
      if (event.keyCode === 13) {
        this.performSearch();
      }
    }

    searchClick() {
      this.performSearch();
    }

    performSearch() {
      const searchValue = this.nameTarget.value;
      window.location.href = this.urlValue + encodeURIComponent(searchValue);
    }
}
