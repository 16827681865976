import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="close-modal"
export default class extends Controller {
  static targets = ['editModal']
  connect() {
    // console.log("connected")
    addEventListener("turbo:submit-end", ((event) => {
      // this.element.parentElement.removeAttribute("src")
      this.element.remove()
    }))
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  hideTagEditModal(e) {
    this.editModalTarget.classList.add('hidden');
  }
}
