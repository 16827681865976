import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "types" ]


  connect() {
    // Find the type of place either from the google api or the model and display it as selected
  }

  updatePlaceType() {

    // Update the selected option at the top and with the selected tick
    // Hide the tick across all other options
    // Update the place type in the hidden form field

  }
}
