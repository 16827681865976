import { Controller } from "@hotwired/stimulus"
import Typed from 'typed.js';


export default class extends Controller {

  connect() {
    console.log("Typed connected")
    const typed = new Typed('#element', {
      strings: ['paper guides', 'static guides', 'boring guides', 'pdf guides'],
      typeSpeed: 200,
      startDelay: 1000,
      backSpeed: 100,
      backDelay: 700,
      loop: false,
      // loopCount: Infinity,
      showCursor: false,
    });

    const typed2 = new Typed('#element2', {
      strings: ['paper guides', 'static guides', 'boring guides', 'pdf guides'],
      typeSpeed: 200,
      startDelay: 1000,
      backSpeed: 100,
      backDelay: 700,
      loop: false,
      // loopCount: Infinity,
      showCursor: false,
    });
  }
}
