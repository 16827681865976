import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  //   static targets = [
  //   "menu"
  // ]

  connect() {
    setTimeout(() => {
      var loadScreen = document.getElementById('load-screen')
      loadScreen.classList.add("hidden")
      var landingPage = document.getElementById('guide-landing')
      landingPage.classList.remove("hidden")
    }, 2500);
  }

  // toggle() {
  //   if(this.menuTarget.classList.contains('hidden')){
  //     this.menuTarget.classList.remove('hidden')
  //   }else{
  //     this.menuTarget.classList.add('hidden')
  //   }
  // }

}

